import { useEffect } from "react";
import { Modal } from "reactstrap";

import { useAppDispatch } from "../../redux";
import {
  getAllNotificationsAction,
  updateNotificationStatusAction,
} from "../../redux/dashboard/notification-slice";

import SectionHeader from "../section-header";
import Button from "../button";

import Icon from "../../assets/svg";
import { NotificationData } from "../../dashboard/notifications";

type Props = {
  isOpen: boolean;
  close: any;
  notification: NotificationData;
  notificationType: string;
  getNotificationsByType: (params: { type: string; status: string }) => void;
};

const NotificationModal = ({
  isOpen,
  close,
  notification,
  notificationType,
  getNotificationsByType,
}: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    updateNotificationStatus();
  }, []);

  const updateNotificationStatus = async () => {
    if (notification.status === "unread") {
      const updateData = {
        id: notification.id,
        slug: notification.slug,
        status: "read",
      };
      const params = {
        type: "",
        status: "",
      };
      const notificationTypeParams = {
        type: notification.type,
        status: "",
      };
      await dispatch(updateNotificationStatusAction(updateData));

      await dispatch(getAllNotificationsAction(params));

      getNotificationsByType(notificationTypeParams);
    }
  };

  return (
    <Modal className="notification_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={
            <div className="title_box">{notificationType.toUpperCase()}</div>
          }
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <div
          className="notification_details"
          dangerouslySetInnerHTML={{
            __html: `<div><h3>${notification.activity}</h3><br/><p>${notification.message}</p></div>`,
          }}
        ></div>

        <Button className="btn_secondary" text="Close" onClick={close} />
      </div>
    </Modal>
  );
};

export default NotificationModal;
