import { Modal } from "reactstrap";
import { useHistory } from "react-router-dom";

import SectionHeader from "../section-header";
import Button from "../button";

type Props = {
  isOpen: boolean;
  close: any;
};

const UpdateBrandAssetsModal = ({ isOpen, close }: Props) => {
  const history = useHistory();
  return (
    <Modal className="update_brand_assets_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={<div className="title_box">UPDATE BRAND ASSETS</div>}
          // rightSide={
          //   <>
          //     <div className="icon" onClick={close}>
          //       <Icon name="close" />
          //     </div>
          //   </>
          // }
        />
      </div>

      <div className="modal_body">
        <div className="update_content">
          <p className="first-paragraph">
            To continue, you have to update your brand assets in the settings
            page. I will be needing the following to populate your selected
            template:
          </p>
          <div className="asset">
            <div className="bullet"></div>
            <p>Brand color</p>
          </div>
          <div className="asset">
            <div className="bullet"></div>
            <p>Brand logo</p>
          </div>
          {/* <div className="asset">
            <div className="bullet"></div>
            <p>Instagram account link</p>
          </div>
          <div className="asset">
            <div className="bullet"></div>
            <p>Facebook account link</p>
          </div>
          <div className="asset">
            <div className="bullet"></div>
            <p>Twitter account link</p>
          </div> */}
          <div className="asset">
            <div className="bullet"></div>
            <p>Link to privacy policy page</p>
          </div>
          <div className="asset">
            <div className="bullet"></div>
            <p>Link to terms of service page</p>
          </div>
        </div>

        <div className="btn">
          <Button
            text="Update Brand Assets"
            onClick={() => {
              history.push({
                pathname: "/settings",
                state: "/campaigns",
              });
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateBrandAssetsModal;
