import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import PageTitle from "../../components/page-title";
// import Carousel from "../../components/carousel";
import FormInput from "../../components/form-input";
import Button from "../../components/button";
import GoogleAuth from "../../components/google-auth";
import { FaRegEyeSlash, FaRegEnvelope, FaLock, FaRegEye } from "react-icons/fa";

import {
  googleLoginAction,
  loginUserAction,
  shopifyLoginAction,
} from "../../redux/auth/user-slice";

import {
  emailFormValidation,
  passwordFormValidation,
} from "../../utils/functions";
import PageContent from "./page-content";
// import Video from "../../components/video";
import VideoContent from "./video-content";

type FormInputs = {
  email: string;
  password: string;
};

const SignInPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();
  const history = useHistory();
  const targetRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { user } = useSelector((state: any) => state.userSlice);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const shopifySlug = params.get("shopify_login") || "";

  useEffect(() => {
    window.scroll(0, 0);

    if (shopifySlug) {
      loginWithShopify();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to scroll to the target element
  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToContent = () => {
    if (contentRef.current) {
      contentRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const loginWithShopify = async () => {
    setIsSubmitting(true);

    await dispatch(shopifyLoginAction(shopifySlug));

    setIsSubmitting(false);
  };

  const submitForm: SubmitHandler<FormInputs> = async (data: any) => {
    setIsSubmitting(true);

    const payload = new FormData();
    payload.append("email", data.email);
    payload.append("password", data.password);

    await dispatch(loginUserAction(payload));

    setIsSubmitting(false);
  };

  const loginWithGoogle = async (data: any) => {
    const payload = {
      googleId: data?.id,
      email: data?.email,
      firstName: data?.given_name,
      lastName: data?.family_name,
      picture: data?.picture,
      locale: "GB",
      verifiedEmail: data?.verified_email,
    };

    await dispatch(googleLoginAction(payload, true)).then((res: any) => {
      if (res?.user_exists === false) {
        history.push("/sign-up");
      }
    });

    setIsSubmitting(false);
  };

  if (user?.userId && !isSubmitting)
    return (
      <>{window.location.assign(user?.onboarded ? "/home" : "/onboarding")}</>
    );

  return (
    <React.Fragment>
      <PageTitle title="Sign In" />

      <div className="sign_in_page">
        <div className="auth_container">
          <div className="left_side">
            {/* <Carousel /> */}
            <VideoContent scroll={scrollToContent} />
          </div>

          <div className="form_container" ref={targetRef}>
            <form className="form">
              <p className="title">Sign In to your Account</p>
              <p className="sub_title">
                Welcome back! please enter your detail
              </p>

              <FormInput
                type="email"
                placeholder="Email"
                readOnly={isSubmitting}
                errorMessage={errors?.email?.message}
                inputRef={{
                  ...register("email", emailFormValidation(true)),
                }}
                leftIcon={<FaRegEnvelope />}
              />
              <FormInput
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                readOnly={isSubmitting}
                errorMessage={errors?.password?.message}
                inputRef={{
                  ...register("password", passwordFormValidation(true)),
                }}
                leftIcon={<FaLock />}
                rightIcon={showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                iconClick={() => setShowPassword(!showPassword)}
              />

              <div className="forgot">
                <FormInput
                  id="remember"
                  label={
                    <>
                      <span>Remember me</span>
                    </>
                  }
                  type="checkbox"
                  value={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  readOnly={isSubmitting}
                />

                <Link to="/forgot-password">Forgot password?</Link>
              </div>

              <div className="actions">
                <Button
                  text="Sign In"
                  type="submit"
                  onClick={handleSubmit((data) => submitForm(data))}
                  loading={isSubmitting}
                  disabled={!isValid || isSubmitting}
                />

                <GoogleAuth
                  onSubmit={(profile: any) => loginWithGoogle(profile)}
                  isSubmitting={isSubmitting}
                  setIsSubmitting={setIsSubmitting}
                />

                <p className="sign_up_text">
                  Don’t have an account? <Link to="/sign-up">Sign Up</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
        <PageContent scrollToTarget={scrollToTarget} contentRef={contentRef} />
      </div>
    </React.Fragment>
  );
};

export default SignInPage;
