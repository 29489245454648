import { NotificationData } from ".";
import Table from "../../components/table";
import { formatNotificationDate } from "../../utils/functions";
import Loader from "../../components/loader";
import NoData from "../../components/no-data";
import classNames from "classnames";

type Props = {
  setNotificationDetails: React.Dispatch<
    React.SetStateAction<NotificationData>
  >;
  setIsNotificationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  notifications: NotificationData[] | undefined;
  isFetchingNotifications: boolean;
  notificationType: string;
};

const NotificationTableView = ({
  setNotificationDetails,
  setIsNotificationModalOpen,
  notifications,
  isFetchingNotifications,
  notificationType,
}: Props) => {
  const handleClick = async (notification: NotificationData) => {
    setNotificationDetails(notification);
    setIsNotificationModalOpen(true);
  };
  return (
    <div className="notification_table_container">
      {isFetchingNotifications ? (
        <Loader />
      ) : notifications?.length === 0 || !notifications ? (
        <NoData text={`no notifications found for ${notificationType}`} />
      ) : (
        <div className="table_container">
          <Table isLoading={false} headers={[]} noRecord={false}>
            {notifications?.map((notification) => (
              <tr
                className="table_row"
                key={notification.id}
                onClick={() => handleClick(notification)}
              >
                <td>
                  <div
                    className={classNames("title", {
                      read: notification.status === "read",
                    })}
                  >
                    <input className="check-input" type={"checkbox"} />
                    <p>{notification.activity}</p>
                  </div>
                </td>
                <td>
                  <p className="content">
                    {notification.message}
                    <span>See More</span>
                  </p>
                </td>
                <td>
                  <p className="date">
                    {formatNotificationDate(notification.created_at)}
                  </p>
                </td>
              </tr>
            ))}
          </Table>
        </div>
      )}
    </div>
  );
};

export default NotificationTableView;
