import { createSlice } from "@reduxjs/toolkit";
import { API_URLS, getRequest, postRequest } from "../../api";

import { setAlert } from "../components/components-slice";

import { getRequestError, sendErrorLogsToDB } from "../../utils/functions";

type Props = {
  brandData: null;
  isFetchingBrandData: boolean;
  isSubmittingBrandData: boolean;
  isUpdatingBrandData: boolean;
  isUpdatingBrandTones: boolean;
  isUpdatingBrandStoreData: boolean;
  isUpdatingBrandWritingApproach: boolean;
  isUpdatingBrandFiles: boolean;
  isUpdatingBrandSummary: boolean;
};

export const initialState: Props = {
  brandData: null,
  isFetchingBrandData: false,
  isSubmittingBrandData: false,
  isUpdatingBrandData: false,
  isUpdatingBrandTones: false,
  isUpdatingBrandStoreData: false,
  isUpdatingBrandWritingApproach: false,
  isUpdatingBrandFiles: false,
  isUpdatingBrandSummary: false,
};

// Slice
const slice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    setIsFetchingBrandData: (state, { payload }) => {
      state.isFetchingBrandData = payload;
    },
    getBrandDataSuccess: (state, { payload }) => {
      state.brandData = payload;
    },
    setIsSubmittingBrandData: (state, { payload }) => {
      state.isSubmittingBrandData = payload;
    },

    setIsUpdatingBrandData: (state, { payload }) => {
      state.isUpdatingBrandData = payload;
    },
    setIsUpdatingBrandTones: (state, { payload }) => {
      state.isUpdatingBrandTones = payload;
    },
    setIsUpdatingBrandStoreData: (state, { payload }) => {
      state.isUpdatingBrandStoreData = payload;
    },
    setIsUpdatingBrandWritingApproach: (state, { payload }) => {
      state.isUpdatingBrandWritingApproach = payload;
    },
    setIsUpdatingBrandFiles: (state, { payload }) => {
      state.isUpdatingBrandFiles = payload;
    },
    setIsUpdatingBrandSummary: (state, { payload }) => {
      state.isUpdatingBrandSummary = payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  setIsFetchingBrandData,
  getBrandDataSuccess,
  setIsSubmittingBrandData,
  // setIsUpdatingBrandData,
  setIsUpdatingBrandTones,
  setIsUpdatingBrandStoreData,
  setIsUpdatingBrandWritingApproach,
  setIsUpdatingBrandFiles,
  setIsUpdatingBrandSummary,
} = slice.actions;

export const getBrandDataAction = (params?: any) => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingBrandData(true));

    const res = await getRequest({
      url: API_URLS?.getBrandData,
      params,
    });

    dispatch(getBrandDataSuccess(res?.data?.data));
    dispatch(setIsFetchingBrandData(false));

    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingBrandData(false));
    await sendErrorLogsToDB(
      errorMessage,
      "brand (store) settings",
      "get brand data"
    );
  }
};

export const submitBrandDataAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsSubmittingBrandData(true));

    const res = await postRequest({
      url: API_URLS?.addBrandData,
      params: null,
      data,
      formData: true,
    });
    dispatch(setAlert(true, "success", res?.data?.message));
    dispatch(setIsSubmittingBrandData(false));

    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsSubmittingBrandData(false));
    await sendErrorLogsToDB(
      errorMessage,
      "brand (store) settings",
      "save brand data in DB"
    );
  }
};
// export const updateBrandDataAction =
//   (data: any, isBrandSummaryUpdate: boolean) => async (dispatch: any) => {
//     try {
//       dispatch(setIsUpdatingBrandData(true));

//       const res = await postRequest({
//         url: API_URLS?.updateBrandData,
//         params: null,
//         data,
//         formData: false,
//       });
//       if (isBrandSummaryUpdate) {
//         dispatch(setAlert(true, "success", res?.data?.message));
//         dispatch(setIsUpdatingBrandData(false));
//         // dispatch(updateBrandDataSuccess(res?.data?.data));
//       }

//       return { success: true, updatedBrandData: res?.data?.data };
//     } catch (err) {
//       const errorMessage = getRequestError(err);
//       dispatch(setAlert(true, "error", errorMessage));

//       dispatch(setIsUpdatingBrandData(false));
//     }
//   };

export const updateBrandTonesAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsUpdatingBrandTones(true));

    await postRequest({
      url: API_URLS?.updateBrandTones,
      params: null,
      data,
      formData: false,
    });

    // dispatch(setAlert(true, "success", res?.data?.message));
    dispatch(setIsUpdatingBrandTones(false));

    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsUpdatingBrandTones(false));
    await sendErrorLogsToDB(
      errorMessage,
      "brand (store) settings",
      "update brand tones"
    );
  }
};

export const updateBrandStoreDataAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsUpdatingBrandStoreData(true));

      await postRequest({
        url: API_URLS?.updateBrandStoreData,
        params: null,
        data,
        formData: false,
      });

      // dispatch(setAlert(true, "success", res?.data?.message));
      dispatch(setIsUpdatingBrandStoreData(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsUpdatingBrandStoreData(false));
      await sendErrorLogsToDB(
        errorMessage,
        "brand (store) settings",
        "update brand store data"
      );
    }
  };
export const updateBrandWritingApproachAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsUpdatingBrandWritingApproach(true));

      await postRequest({
        url: API_URLS?.updateBrandWritingApproach,
        params: null,
        data,
        formData: false,
      });

      // dispatch(setAlert(true, "success", res?.data?.message));
      dispatch(setIsUpdatingBrandWritingApproach(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsUpdatingBrandWritingApproach(false));
      await sendErrorLogsToDB(
        errorMessage,
        "brand (store) settings",
        "update brand writing approach"
      );
    }
  };

export const updateBrandFilesAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsUpdatingBrandFiles(true));

    await postRequest({
      url: API_URLS?.updateBrandFiles,
      params: null,
      data,
      formData: true,
    });

    // dispatch(setAlert(true, "success", res?.data?.message));
    dispatch(setIsUpdatingBrandFiles(false));

    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsUpdatingBrandFiles(false));
    await sendErrorLogsToDB(
      errorMessage,
      "brand (store) settings",
      "update brand files"
    );
  }
};

export const updateBrandSummaryAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsUpdatingBrandSummary(true));

      const res = await postRequest({
        url: API_URLS?.updateBrandSummary,
        params: null,
        data,
        formData: false,
      });

      dispatch(setAlert(true, "success", res?.data?.message));
      dispatch(setIsUpdatingBrandSummary(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsUpdatingBrandSummary(false));
      await sendErrorLogsToDB(
        errorMessage,
        "brand (store) settings",
        "update brand summary"
      );
    }
  };

export const getBrandSummaryAction = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setIsUpdatingBrandSummary(true));
    const res = await getRequest({
      url: API_URLS?.getBrandSummary,
      params,
    });

    dispatch(setIsUpdatingBrandSummary(false));

    return { success: true, brandSummary: res?.data?.data };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
    dispatch(setIsUpdatingBrandSummary(false));
    await sendErrorLogsToDB(
      errorMessage,
      "brand (store) settings",
      "get brand summary"
    );
  }
};
