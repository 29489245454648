import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import PageTitle from "../../components/page-title";
// import Carousel from "../../components/carousel";
import FormInput from "../../components/form-input";
import Button from "../../components/button";
import GoogleAuth from "../../components/google-auth";

import {
  createUserAction,
  createUserWithGoogleAction,
  googleLoginAction,
  loginUserAction,
} from "../../redux/auth/user-slice";

import {
  emailFormValidation,
  passwordFormValidation,
  textFormValidation,
} from "../../utils/functions";
import {
  FaRegEyeSlash,
  FaRegEnvelope,
  FaRegEye,
  // FaShopify,
  FaLock,
  FaStoreAlt,
} from "react-icons/fa";
// import Video from "../../components/video";
import PageContent from "./page-content";
import VideoContent from "./video-content";

type FormInputs = {
  company_name: string;
  email: string;
  password: string;
};

const SignUpPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);

  const targetRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [step, setStep] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userAgrees, setUserAgrees] = useState(false);
  const [googleUser, setGoogleUser] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const queryStep = params.get("step");

  useEffect(() => {
    window.scroll(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryStep) {
      setStep(parseInt(queryStep));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStep]);

  // Function to scroll to the target element
  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToContent = () => {
    if (contentRef.current) {
      contentRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const submitForm: SubmitHandler<FormInputs> = async (data: any) => {
    setIsSubmitting(true);

    const payload = new FormData();
    payload.append("company_name", data.company_name);
    payload.append("email", data.email);
    payload.append("password", data.password);

    await dispatch(createUserAction(payload)).then((res) => {
      if (res?.success) {
        payload.delete("company_name");
        dispatch(loginUserAction(payload));
      }
    });

    setIsSubmitting(false);
  };

  const loginWithGoogle = async (data: any) => {
    const payload = {
      googleId: data?.id,
      email: data?.email,
      firstName: data?.given_name,
      lastName: data?.family_name || data?.given_name,
      picture: data?.picture,
      locale: "GB",
      verifiedEmail: data?.verified_email,
    };
    setGoogleUser(payload);

    await dispatch(googleLoginAction(payload)).then((res: any) => {
      if (res?.user_exists === false) {
        setStep(2);
      }
    });

    setIsSubmitting(false);
  };

  const createUserWithGoogle = async (e: any) => {
    e.preventDefault();

    setIsSubmitting(true);

    const payload = {
      ...googleUser,
      companyName,
    };

    await dispatch(createUserWithGoogleAction(payload));

    setIsSubmitting(false);
  };

  const termsUrl = "https://google.com";
  const privacyUrl = "https://google.com";

  if (user?.userId && !isSubmitting)
    return (
      // <>{window.location.assign("/onboarding-questions?store_url=none")}</>
      <>{window.location.assign("/onboarding")}</>
    );

  return (
    <React.Fragment>
      <PageTitle title="Sign Up" />

      <div className="sign_in_page">
        <div className="auth_container sign_up">
          <div className="left_side">
            <VideoContent scroll={scrollToContent} />
          </div>

          <div className="form_container" ref={targetRef}>
            {step === 1 && (
              <form className="form">
                <p className="title">Create your Konvas AI account</p>

                <FormInput
                  type="name"
                  placeholder="Brand/Store Name"
                  readOnly={isSubmitting}
                  errorMessage={errors?.company_name?.message}
                  inputRef={{
                    ...register("company_name", textFormValidation(true)),
                  }}
                  leftIcon={<FaStoreAlt />}
                />
                <FormInput
                  type="email"
                  placeholder="Email"
                  readOnly={isSubmitting}
                  errorMessage={errors?.email?.message}
                  inputRef={{
                    ...register("email", emailFormValidation(true)),
                  }}
                  leftIcon={<FaRegEnvelope />}
                />
                <FormInput
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  readOnly={isSubmitting}
                  errorMessage={errors?.password?.message}
                  inputRef={{
                    ...register("password", passwordFormValidation(true)),
                  }}
                  leftIcon={<FaLock />}
                  rightIcon={showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                  iconClick={() => setShowPassword(!showPassword)}
                />

                <div className="forgot">
                  <FormInput
                    id="remember"
                    label={
                      <>
                        By Clicking Sign Up, you agree to Konvas AI’s{" "}
                        <a href={termsUrl} target="_blank" rel="noreferrer">
                          Terms
                        </a>{" "}
                        and{" "}
                        <a href={privacyUrl} target="_blank" rel="noreferrer">
                          Privacy Policy
                        </a>
                      </>
                    }
                    type="checkbox"
                    value={userAgrees}
                    onChange={() => setUserAgrees(!userAgrees)}
                    readOnly={isSubmitting}
                  />
                </div>

                <div className="actions">
                  <Button
                    text="Sign Up"
                    type="submit"
                    onClick={handleSubmit((data) => submitForm(data))}
                    loading={isSubmitting}
                    disabled={!userAgrees || !isValid || isSubmitting}
                  />

                  <GoogleAuth
                    onSubmit={(profile: any) => loginWithGoogle(profile)}
                    isSubmitting={isSubmitting}
                    setIsSubmitting={setIsSubmitting}
                  />

                  <p className="sign_up_text">
                    Already have an account? <Link to="/">Log In</Link>
                  </p>
                </div>
              </form>
            )}

            {step === 2 && (
              <form className="form">
                <p className="title">Fill your Information</p>

                <FormInput
                  type="name"
                  placeholder="Brand/Store Name"
                  onChange={(e) => setCompanyName(e?.target?.value)}
                  readOnly={isSubmitting}
                  leftIcon="store"
                />

                <div className="actions">
                  <Button
                    text="Create Account"
                    type="submit"
                    onClick={(e: any) => createUserWithGoogle(e)}
                    loading={isSubmitting}
                    disabled={!companyName || isSubmitting}
                  />

                  <p className="sign_up_text">
                    Already have an account? <Link to="/">Log In</Link>
                  </p>
                </div>
              </form>
            )}
          </div>
        </div>
        <PageContent scrollToTarget={scrollToTarget} contentRef={contentRef} />
      </div>
    </React.Fragment>
  );
};

export default SignUpPage;
