import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import PageTitle from "../../components/page-title";
// import Carousel from "../../components/carousel";
import FormInput from "../../components/form-input";
import Button from "../../components/button";
import Loader from "../../components/loader";

import {
  createShopifyUserAction,
  loginUserAction,
} from "../../redux/auth/user-slice";

import {
  emailFormValidation,
  passwordFormValidation,
  textFormValidation,
  useLocalStorage,
} from "../../utils/functions";
import { API_URLS, getRequest } from "../../api";
import { APP_USER } from "../../utils/constants";
import Icon from "../../assets/svg";
// import Video from "../../components/video";
import PageContent from "./page-content";
import VideoContent from "./video-content";

type FormInputs = {
  fullname: string;
  company_name: string;
  email: string;
  phone: string;
  password: string;
};

type ShopifyDetails = {
  shopify_business_email: string;
  shopify_business_name: string;
  shopify_owner_name: string;
  shopify_phone: string;
  shopify_url: string;
};

const ShopifySignUpPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);

  const [shopifyDetails, setShopifyDetails] = useState<ShopifyDetails>();

  const [showPassword, setShowPassword] = useState(false);
  const [userAgrees, setUserAgrees] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  // const queryStep = params.get("step");
  const slug = params.get("slug");

  const userObject = useLocalStorage.get(APP_USER);

  if (userObject) {
    window.location.assign("/onboarding-questions");
  }

  const getShopifyDetails = async () => {
    const response = await getRequest({
      url: `${API_URLS.getShopifyDetails}${slug}`,
      params: "",
    });

    setShopifyDetails(response.data.data);
  };

  useEffect(() => {
    window.scroll(0, 0);

    // useLocalStorage.remove(APP_USER);

    getShopifyDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.userId && !isSubmitting && shopifyDetails) {
      window.location.assign(
        `/onboarding-questions?store_url=${shopifyDetails.shopify_url}`
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const submitForm: SubmitHandler<FormInputs> = async (data: any) => {
    setIsSubmitting(true);

    const payload = new FormData();
    payload.append("contact_name", data.fullname);
    payload.append("company_name", data.company_name);
    payload.append("email", data.email);
    payload.append("phone", data.phone);
    payload.append("slug", `${slug}`);
    payload.append("password", data.password);

    await dispatch(createShopifyUserAction(payload)).then((res) => {
      if (res?.success) {
        payload.delete("contact_name");
        payload.delete("company_name");
        payload.delete("phone");
        payload.delete("slug");

        dispatch(loginUserAction(payload));
      }
    });

    setIsSubmitting(false);
  };

  const termsUrl = "https://google.com";
  const privacyUrl = "https://google.com";

  return (
    <React.Fragment>
      <PageTitle title="Sign Up" />

      <div className="sign_in_page">
        <div className="auth_container sign_up">
          <div className="left_side">
            <VideoContent />
          </div>

          <div className="form_container">
            {shopifyDetails ? (
              <form className="form">
                <p className="title">Continue your Shopify installation</p>
                <FormInput
                  type="name"
                  placeholder="fullname"
                  value={shopifyDetails?.shopify_owner_name}
                  readOnly={false}
                  errorMessage={errors?.fullname?.message}
                  inputRef={{
                    ...register("fullname", textFormValidation(true)),
                  }}
                  leftIcon={<Icon name="user" />}
                />
                <FormInput
                  type="name"
                  placeholder="Brand/Store Name"
                  value={shopifyDetails?.shopify_business_name}
                  readOnly={false}
                  errorMessage={errors?.company_name?.message}
                  inputRef={{
                    ...register("company_name", textFormValidation(true)),
                  }}
                  leftIcon={<Icon name="store" />}
                />
                <FormInput
                  type="email"
                  placeholder="Email"
                  value={shopifyDetails?.shopify_business_email}
                  readOnly={false}
                  errorMessage={errors?.email?.message}
                  inputRef={{
                    ...register("email", emailFormValidation(true)),
                  }}
                  leftIcon={<Icon name="mail" />}
                />
                <FormInput
                  type="phone"
                  placeholder="phone"
                  value={shopifyDetails?.shopify_phone}
                  readOnly={false}
                  errorMessage={errors?.phone?.message}
                  inputRef={{
                    ...register("phone", textFormValidation(true)),
                  }}
                  leftIcon={<Icon name="phone" />}
                />
                <FormInput
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  readOnly={isSubmitting}
                  errorMessage={errors?.password?.message}
                  inputRef={{
                    ...register("password", passwordFormValidation(true)),
                  }}
                  leftIcon={<Icon name="lock" />}
                  rightIcon={<Icon name={showPassword ? "eyeOff" : "eye"} />}
                  iconClick={() => setShowPassword(!showPassword)}
                />

                <div className="forgot">
                  <FormInput
                    id="remember"
                    label={
                      <>
                        By Clicking Sign Up, you agree to Konvas AI’s{" "}
                        <a href={termsUrl} target="_blank" rel="noreferrer">
                          Terms
                        </a>{" "}
                        and{" "}
                        <a href={privacyUrl} target="_blank" rel="noreferrer">
                          Privacy Policy
                        </a>
                      </>
                    }
                    type="checkbox"
                    value={userAgrees}
                    onChange={() => setUserAgrees(!userAgrees)}
                    readOnly={isSubmitting}
                  />
                </div>

                <div className="actions">
                  <Button
                    text="Sign Up"
                    type="submit"
                    onClick={handleSubmit((data) => submitForm(data))}
                    loading={isSubmitting}
                    disabled={!userAgrees || !isValid || isSubmitting}
                  />

                  <p className="sign_up_text">
                    Already have an account? <Link to="/">Log In</Link>
                  </p>
                </div>
              </form>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <PageContent />
      </div>
    </React.Fragment>
  );
};

export default ShopifySignUpPage;
