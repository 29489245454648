import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";

import SectionHeader from "../../components/section-header";
import Loader from "../../components/loader";
import Table from "../../components/table";
import ConfirmationModal from "../../components/modal/confirmation-modal";

import {
  getSegmentsByIdAction,
  deleteSegmentsAction,
} from "../../redux/dashboard/segments-slice";

import { formatNumber, getInitials } from "../../utils/functions";

import Icon from "../../assets/svg";

type SegmentInfoProps = {
  id: string;
  setShowSegmentDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

const SegmentInfoPage = ({ id, setShowSegmentDetails }: SegmentInfoProps) => {
  const dispatch = useAppDispatch();

  const { isFetchingSegmentData, segmentData, isDeletingSegment } = useSelector(
    (state: any) => state.segmentsSlice
  );

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getSegmentsByIdAction(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSegment = () => {
    dispatch(deleteSegmentsAction(id)).then((res: any) => {
      if (res?.success === true) {
        setShowSegmentDetails(false);
        window.location.reload();
      }
    });
  };

  const tableHeaders = ["Customer name", "Orders Count", "Completed Purchases"];

  return (
    <React.Fragment>
      <div className="segments_info_page_container">
        {isFetchingSegmentData && <Loader />}

        {!isFetchingSegmentData && (
          <>
            <SectionHeader
              goBack
              goBackOnClick={() => setShowSegmentDetails(false)}
              title={segmentData?.segment_name}
              printer
              rightSide={
                <>
                  <div
                    className="icon"
                    onClick={() => setIsConfirmationModalOpen(true)}
                  >
                    <Icon name="trash" />
                  </div>
                </>
              }
            />

            <div className="content">
              <div className="left_side">
                <div className="info summary">
                  <p className="title">Segment Summary</p>

                  <p className="details">{segmentData?.segment_description}</p>
                </div>

                {/* <div className="info campaigns">
                  <p className="title">Ongoing Campaigns</p>

                  <div className="list">
                    {segmentInfoData.campaigns?.map((item, i) => (
                      <div key={i} className="item">
                        <FormInput
                          id="selectAll"
                          type="checkbox"
                          value={isCampaignSelected(item.id)}
                          onChange={() => handleSelectCampaign(item.id)}
                          readOnly={isFetchingSegmentData}
                        />

                        <div className="icon" onClick={() => {}}>
                          <Icon name={item.favorite ? "starFilled" : "star"} />
                        </div>

                        <p className="title">{item.name}</p>
                      </div>
                    ))}
                  </div>
                </div> */}

                {/* <div className="info insights">
                  <p className="title">Generated Insights</p>

                  <ul>
                    {segmentInfoData.insights?.map((item: any, i: any) => (
                      <li key={i}>{item.name}</li>
                    ))}
                  </ul>
                </div> */}
              </div>

              <div className="table_container">
                <Table
                  isLoading={isFetchingSegmentData}
                  headers={tableHeaders}
                  noRecord={segmentData?.customers?.length < 1}
                >
                  {segmentData?.customers
                    ?.filter((_customer: any, index: number) => index < 100)
                    ?.map((row: any, i: any) => (
                      <tr key={i}>
                        <td>
                          <div className="customer">
                            <div className="image">
                              <p className="initials">
                                {getInitials(
                                  `${row?.first_name} ${row?.last_name}`
                                )}
                              </p>
                            </div>
                            <p className="text_wrap">
                              {`${row?.first_name} ${row?.last_name}`}
                            </p>
                          </div>
                        </td>
                        <td>
                          <p>{row?.orders_count}</p>
                        </td>
                        <td>
                          <p>
                            {typeof row?.total_spent === "string"
                              ? row?.total_spent
                              : `${formatNumber(row?.total_spent, 2)} USD`}
                          </p>
                        </td>
                      </tr>
                    ))}
                </Table>
              </div>
            </div>
          </>
        )}

        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          close={() => setIsConfirmationModalOpen(false)}
          confirmationText={`Are you sure you want to delete ${segmentData?.segment_name}?`}
          onSubmit={deleteSegment}
          loading={isDeletingSegment}
        />
      </div>
    </React.Fragment>
  );
};

export default SegmentInfoPage;
