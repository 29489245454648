import React, { useState } from "react";
import moment from "moment";

import { funnel } from "../../assets/img";

import { NotificationData } from ".";

import Button from "../../components/button";
import DatePickerComponent from "../../components/datepicker";

type Props = {
  notifications: NotificationData[];
  setNotificationsByType: React.Dispatch<
    React.SetStateAction<NotificationData[] | undefined>
  >;
};

const NotificationDateFilter = ({
  notifications,
  setNotificationsByType,
}: Props) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const handleFilter = () => {
    const start_date = moment(startDate).format("YYYY-MM-DD");
    const end_date = moment(endDate).format("YYYY-MM-DD");

    setNotificationsByType((_prev: NotificationData[] | undefined) => {
      const start = new Date(start_date);
      const end = new Date(end_date);

      return notifications?.filter((notification: NotificationData) => {
        const createdAt = new Date(
          moment(notification.created_at).format("YYYY-MM-DD")
        );

        return createdAt >= start && createdAt <= end;
      });
    });
  };

  return (
    <div className="notification_date_filter">
      <div className="heading">
        <img src={funnel} alt="" className="filter_icon" />
        <p>Filter by</p>
      </div>
      <div className="date">
        {/* <div>
          <p>Day</p>
          <FormSelect
            name="filterSelect"
            defaultValue={"Select One"}
            options={[{ label: "1", value: "1" }]}
            onChange={(value: any) => null}
          />
        </div>
        <div>
          <p>Month</p>
          <FormSelect
            name="filterSelect"
            defaultValue={"Select One"}
            options={[{ label: "1", value: "1" }]}
            onChange={(value: any) => null}
          />
        </div>
        <div>
          <p>Year</p>
          <FormSelect
            name="filterSelect"
            defaultValue={"Select One"}
            options={[{ label: "1", value: "1" }]}
            onChange={(value: any) => null}
          />
        </div> */}
        <div className="datepicker">
          <DatePickerComponent
            startDate={startDate}
            endDate={endDate}
            text="Click here to select date"
            onChange={(update: any) => {
              setDateRange(update);
            }}
            // isClearable={true}
            range={true}
          />
        </div>
        {/* <div className="btn_container"> */}
        <Button
          text="Search"
          onClick={handleFilter}
          disabled={!(startDate && endDate)}
        />
        {/* </div> */}
      </div>
    </div>
  );
};

export default NotificationDateFilter;
