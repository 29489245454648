import { createSlice } from "@reduxjs/toolkit";
import { API_URLS, getRequest } from "../../api";

import { setAlert } from "../components/components-slice";

import { getRequestError, sendErrorLogsToDB } from "../../utils/functions";

type Props = {
  dailyVideoSrc: string;
  isFetchingInsights: boolean;
  insightsData: null;
  isFetchingReports: boolean;
  isFetchingSetupProgress: boolean;
  reportsData: [];
};
export const initialState: Props = {
  dailyVideoSrc: "",
  isFetchingInsights: true,
  insightsData: null,
  isFetchingReports: true,
  isFetchingSetupProgress: true,
  reportsData: [],
};

interface DateRangeParams {
  startDate: string;
  endDate: string;
}

// Slice
const slice = createSlice({
  name: "home",
  initialState,
  reducers: {
    getDailyVideoSuccess: (state, { payload }) => {
      state.dailyVideoSrc = payload;
    },
    setIsFetchingInsights: (state, { payload }) => {
      state.isFetchingInsights = payload;
    },
    setIsFetchingReports: (state, { payload }) => {
      state.isFetchingReports = payload;
    },
    setIsFetchingSetupProgress: (state, { payload }) => {
      state.isFetchingSetupProgress = payload;
    },
    getInsightsSuccess: (state, { payload }) => {
      state.insightsData = payload;
    },
    getReportsSuccess: (state, { payload }) => {
      state.reportsData = payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  getDailyVideoSuccess,
  setIsFetchingInsights,
  setIsFetchingSetupProgress,
  getInsightsSuccess,
  // setIsFetchingReports,
  // getReportsSuccess,
} = slice.actions;

export const getDailyVideoAction = (data: string) => async (dispatch: any) => {
  try {
    var responseStatus = 0;

    const result = await fetch(`${API_URLS?.getDailyVideo}/${data}.mp4`)
      .then((response: any) => {
        responseStatus = response.status;
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }: any) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      // Create an object URL for the response
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      // Update image
      .then((url) => url);

    dispatch(getDailyVideoSuccess(result));
    return { status: responseStatus };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
    await sendErrorLogsToDB(errorMessage, "dashboard(home)", "get daily video");
  }
};

export const getNoDailyVideoAction = () => async (dispatch: any) => {
  try {
    var responseStatus = 0;

    const result = await fetch(API_URLS?.getNoDailyVideo)
      .then((response: any) => {
        responseStatus = response.status;
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }: any) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      // Create an object URL for the response
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      // Update image
      .then((url) => url);

    dispatch(getDailyVideoSuccess(result));
    return { status: responseStatus };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getTopInsightsAction = (data: string) => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingInsights(true));
    const res = await getRequest({
      url: `${API_URLS?.getHomeInsights}/${data}`,
      params: null,
    });

    dispatch(getInsightsSuccess(res?.data?.data || ""));
    dispatch(setIsFetchingInsights(false));

    return { status: res?.data?.status };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingInsights(false));
    await sendErrorLogsToDB(
      errorMessage,
      "dashboard(home)",
      "get top insights for store"
    );
  }
};

export const getChartDataAction =
  (dateRange: DateRangeParams) => async (dispatch: any) => {
    try {
      const res = await getRequest({
        url: API_URLS?.getChartData,
        params: dateRange,
      });

      return { status: res?.data?.status, data: res?.data?.data };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
      await sendErrorLogsToDB(
        errorMessage,
        "dashboard(home)",
        "get chart data"
      );
    }
  };
export const getSetupProgressAction = () => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingSetupProgress(true));
    const res = await getRequest({
      url: API_URLS?.getSetupProgress,
      params: null,
    });

    if (res.data) dispatch(setIsFetchingSetupProgress(false));

    return { ai_status: res?.data?.ai };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
    dispatch(setIsFetchingSetupProgress(false));
    await sendErrorLogsToDB(
      errorMessage,
      "dashboard(home)",
      "get setup progress"
    );
  }
};
