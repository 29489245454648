import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { getNotificationsAction } from "../../redux/dashboard/notification-slice";

import PageTitle from "../../components/page-title";
import NotificationModal from "../../components/modal/notification-modal";

import NotificationTab from "./notification-tab";
import NotificationTableView from "./notification-table-view";
import NotificationListView from "./notification-list-view";
import NotificationsTopbar from "./notifications-topbar";
import DeleteNotificationConfirmation from "../../components/modal/delete-notification-confirmation";

export interface NotificationData {
  activity: string;
  created_at: string;
  id: string;
  message: string;
  slug: string;
  status: string;
  type: string;
}

const viewTypeOptions = [
  { label: "List", value: "list" },
  { label: "Table", value: "table" },
];

const NotificationsPage = () => {
  const dispatch = useAppDispatch();

  const {
    notifications,
    isFetchingNotifications,
    isDeletingNotification,
    isUpdatingNotificationStatus,
  } = useSelector((state: any) => state.notificationSlice);

  const [activeTab, setActiveTab] = useState("Campaigns");
  const [viewType, setViewType] = useState(viewTypeOptions[0]);
  const [params, setParams] = useState({ type: "campaign", status: "" });
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [isDeleteNotificationModalOPen, setIsDeleteNotificationModalOPen] =
    useState(false);
  const [notificationsByType, setNotificationsByType] = useState<
    NotificationData[] | undefined
  >();
  const [allNotifications, setAllNotifications] = useState<
    NotificationData[] | undefined
  >();
  const [NotificationDetails, setNotificationDetails] =
    useState<NotificationData>({
      activity: "",
      created_at: "",
      id: "",
      message: "",
      slug: "",
      status: "",
      type: "",
    });

  const [activeNotificationsId, setActiveNotificationsId] = useState<string[]>(
    []
  );
  const [
    showDeleteAllSelectedNotificationIcon,
    setShowDeleteAllSelectedNotificationIcon,
  ] = useState(false);

  useEffect(() => {
    // dispatch(getNotificationsAction(params));
    getNotificationsByType(params);
  }, [params]);

  const getNotificationsByType = async (params: {
    type: string;
    status: string;
  }) => {
    const res = await dispatch(getNotificationsAction(params));
    setNotificationsByType(res?.notifications);
  };

  // useEffect(() => {
  //   console.log(`notification (${params.type}):`, notificationsByType);
  // }, [notificationsByType]);

  // useEffect(() => {
  //   console.log(`All notifications:`, allNotifications);
  // }, [allNotifications]);

  //callback to get all notifications from the Topbar component
  const getAllNotifications = (notifications: any) => {
    setAllNotifications(notifications);
  };

  return (
    <div className="notifications_page_container">
      <PageTitle title="Notifications" />

      <NotificationsTopbar
        viewType={viewType}
        setViewType={setViewType}
        viewTypeOptions={viewTypeOptions}
        getAllNotifications={getAllNotifications}
        notifications={notifications}
        notificationsByType={notificationsByType}
        setNotificationsByType={setNotificationsByType}
        allNotifications={allNotifications}
        activeNotificationsId={activeNotificationsId}
        setActiveNotificationsId={setActiveNotificationsId}
        getNotificationsByType={getNotificationsByType}
        notificationTypeParams={params}
        showDeleteIcon={showDeleteAllSelectedNotificationIcon}
        setShowDeleteIcon={setShowDeleteAllSelectedNotificationIcon}
        setIsDeleteNotificationModalOPen={setIsDeleteNotificationModalOPen}
      />
      <NotificationTab
        allNotifications={allNotifications}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setNotificationParams={setParams}
      />
      {viewType.value === "list" ? (
        <NotificationListView
          setNotificationDetails={setNotificationDetails}
          setIsNotificationModalOpen={setIsNotificationModalOpen}
          setIsDeleteNotificationModalOPen={setIsDeleteNotificationModalOPen}
          notifications={notificationsByType}
          isFetchingNotifications={isFetchingNotifications}
          isUpdatingNotificationStatus={isUpdatingNotificationStatus}
          isDeletingNotification={isDeletingNotification}
          notificationType={activeTab}
          activeNotificationsId={activeNotificationsId}
          setActiveNotificationsId={setActiveNotificationsId}
          showDeleteAllSelectedNotificationIcon={
            showDeleteAllSelectedNotificationIcon
          }
        />
      ) : (
        <NotificationTableView
          setNotificationDetails={setNotificationDetails}
          setIsNotificationModalOpen={setIsNotificationModalOpen}
          notifications={notificationsByType}
          isFetchingNotifications={isFetchingNotifications}
          notificationType={activeTab}
        />
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          close={() => setIsNotificationModalOpen(false)}
          notification={NotificationDetails}
          notificationType={activeTab}
          getNotificationsByType={getNotificationsByType}
        />
      )}
      {isDeleteNotificationModalOPen && (
        <DeleteNotificationConfirmation
          isOpen={isDeleteNotificationModalOPen}
          close={() => setIsDeleteNotificationModalOPen(false)}
          isDeletingNotification={isDeletingNotification}
          activeNotificationsId={activeNotificationsId}
          getNotificationsByType={getNotificationsByType}
          notificationTypeParams={params}
        />
      )}
    </div>
  );
};

export default NotificationsPage;
