import { useAppDispatch } from "../../redux";
import ConfirmationModal from "./confirmation-modal";
import Button from "../button";
import { deleteNotificationAction } from "../../redux/dashboard/notification-slice";

type Props = {
  isOpen: boolean;
  close: () => void;
  activeNotificationsId: string[];
  isDeletingNotification: boolean;
  getNotificationsByType: (params: { type: string; status: string }) => void;
  notificationTypeParams: { type: string; status: string };
};

const DeleteNotificationConfirmation = ({
  close,
  isOpen,

  activeNotificationsId,
  isDeletingNotification,
  getNotificationsByType,
  notificationTypeParams,
}: Props) => {
  const dispatch = useAppDispatch();

  const deleteNotification = async () => {
    const notificationsIdsToBeDeleted = activeNotificationsId.map((id) => {
      return Number(id);
    });
    const res = await dispatch(
      deleteNotificationAction({ id: notificationsIdsToBeDeleted })
    );
    if (res?.success) {
      getNotificationsByType(notificationTypeParams);
      close();
    }
  };

  const confirmationAction = (
    <div className="btn_box">
      <Button
        text="Yes"
        className="danger"
        onClick={deleteNotification}
        loading={isDeletingNotification}
      />
      <Button text="No" className="" onClick={close} />
    </div>
  );
  return (
    <ConfirmationModal
      confirmationHeader="DELETE"
      confirmationText="Are you sure you want to delete the selected notification(s)?"
      confirmationAction={confirmationAction}
      isOpen={isOpen}
      close={close}
      onSubmit={() => ""}
      loading={false}
    />
  );
};

export default DeleteNotificationConfirmation;
