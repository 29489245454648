import { bellIcon, bullet, letter } from "../../assets/img";
import Icon from "../../assets/svg";
import Button from "../../components/button";
import { NotificationData } from ".";
import { formatNotificationDate } from "../../utils/functions";

type Props = {
  notifications: [];
};

const NotificationDropDown = ({ notifications }: Props) => {
  const handleViewAllNotifications = () => {
    window.location.assign("/notifications");
  };

  return (
    <div className="notification_dropdown">
      <div className="topbar">
        <div className="left">
          <p>NOTIFICATIONS</p>
          <img src={bellIcon} alt="notification_icon" />
        </div>
        <div className="right">
          <Icon name="close" />
        </div>
      </div>
      <div className="content">
        {notifications
          ?.filter((_notification: any, index: number) => index < 2)
          .map((notification: NotificationData) => (
            <div className="item" key={notification.id}>
              <div className="icon">
                <img src={letter} alt="" />
              </div>
              <div className="item_content">
                <p className="item_title">{notification.activity}</p>
                <p
                  className="item_detail"
                  dangerouslySetInnerHTML={{
                    __html: `${notification.message}  <span>See more</span>`,
                  }}
                ></p>

                <p className="item_detail">
                  {formatNotificationDate(notification.created_at)}
                </p>
              </div>
              <div className="bullet">
                <img src={bullet} alt="" />
              </div>
            </div>
          ))}

        {/* <div className="item">
          <div className="icon">
            <img src={graph} alt="" />
          </div>
          <div className="item_content">
            <p className="item_title">You have not reviewed your analytics</p>
            <p className="item_detail">
              You have some new data on your analytics page.{" "}
              <span>See more</span>
            </p>
            <p className="item_detail">27th May 2024, 12:32pm</p>
          </div>
          <div className="bullet">
            <img src={bullet} alt="" />
          </div>
        </div> */}
        <div className="button_div">
          <Button
            text={"See all notifications"}
            onClick={handleViewAllNotifications}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationDropDown;
