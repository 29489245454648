import { useState } from "react";

import SectionHeader from "../../components/section-header";
import Accordion from "../../components/accordion";
import Button from "../../components/button";

import CampaignReportChart from "./campaign-report-chart";

import Icon from "../../assets/svg";

type Props = {
  reportData: any;
  close?: () => void;
  isCampaignReportExpanded: boolean;
  setIsCampaignReportExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

const CampaignReport = ({
  reportData,
  isCampaignReportExpanded,
  setIsCampaignReportExpanded,
}: Props) => {
  const [isSummaryAccordionOpen, setIsSummaryAccordionOpen] = useState(false);
  const [isPerformanceAccordionOpen, setIsPerformanceAccordionOpen] =
    useState(false);

  const dateDisplay = (date: Date) => {
    return `${new Date(date).toDateString()}`;
  };

  const startDate: any = new Date(reportData?.start_date).getTime();
  const today: any = new Date().getTime();

  const hoursDiff = Math.abs(today - startDate) / 36e5;
  const showAnalytics = hoursDiff > 24 ? true : false;

  return (
    <div className="campaign_report">
      <SectionHeader
        title="Campaign Report"
        rightSide={
          <div className="actions">
            {/* <div
              className="icon"
              onClick={() => setIsCampaignReportExpanded(false)}
            >
              <Icon name="close" />
            </div> */}
            {isCampaignReportExpanded ? (
              <div className="back_btn">
                <Button
                  text={"Back to campaigns"}
                  onClick={() => setIsCampaignReportExpanded(false)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        }
      />

      <div className="report_content">
        {showAnalytics ? (
          <div>
            <div className="graph_data">
              <div className="bottom">
                <Accordion
                  open={isSummaryAccordionOpen || isCampaignReportExpanded}
                  title="SUMMARY"
                  setIsOpen={setIsSummaryAccordionOpen}
                  content={
                    <>
                      <div className="header">
                        <div className="info">
                          <p className="title">DATE</p>
                          <div className="date">
                            <Icon name="calendar" />
                            {dateDisplay(reportData.start_date)} -{" "}
                            {dateDisplay(reportData.end_date)}
                          </div>
                        </div>

                        <div className="info">
                          <p className="title">SENT</p>
                          <p className="value sent">{reportData.sent}</p>
                        </div>

                        <div className="info">
                          <p className="title">DELIVERED</p>
                          <p className="value delivered">
                            {reportData?.delivered}
                          </p>
                        </div>
                        <div className="info">
                          <p className="title">PENDING</p>
                          <p className="value pending">{reportData.pending}</p>
                        </div>
                      </div>
                    </>
                  }
                />

                <Accordion
                  open={isPerformanceAccordionOpen || isCampaignReportExpanded}
                  setIsOpen={setIsPerformanceAccordionOpen}
                  title="CAMPAIGN PERFORMANCE"
                  content={
                    <div className="header">
                      <div className="info">
                        <p className="title">REACH</p>
                        {/* <p className="value reach">{reportData?.reached}</p> */}
                        <p className="value reach"> {reportData?.reached}</p>
                      </div>

                      <div className="info">
                        <p className="title">NO. OF OPENS</p>
                        {/* <p className="value opened">{reportData?.opened}</p> */}
                        <p className="value opened"> {reportData?.opened}</p>
                      </div>

                      <div className="info">
                        <p className="title">CLICKS</p>
                        <p className="value clicks">{reportData.clicked}</p>
                      </div>

                      <div className="info">
                        <p className="title">UNSUBSCRIBED</p>
                        <p className="value unsubscribed">
                          {reportData?.unsubscribed}
                        </p>
                      </div>

                      <div className="info">
                        <p className="title">CLICKED_UNIQUE</p>
                        <p className="value clicked_unique">
                          {reportData?.clicked_unique}
                        </p>
                      </div>
                      <div className="info">
                        <p className="title">OPENED_UNIQUE</p>
                        <p className="value opened_unique">
                          {reportData?.opened_unique}
                        </p>
                      </div>
                      {/* <div className="info">
                        <p className="title">OPEN RATE</p>
                        <p className="value open_rate">50%</p>
                      </div> */}

                      {!isCampaignReportExpanded && (
                        <div className="btn_div">
                          <Button
                            text="View full report"
                            onClick={() => setIsCampaignReportExpanded(true)}
                          />
                        </div>
                      )}
                    </div>
                  }
                />
              </div>
              {isCampaignReportExpanded && (
                <CampaignReportChart reportData={reportData} />
              )}
            </div>
            {isCampaignReportExpanded && (
              <div className="report_explanation">
                <div className="data_item">
                  <div className="bullet reach"></div>
                  <p>
                    This campaign email has been sent to{" "}
                    <strong>{reportData.sent}</strong> out of{" "}
                    <strong>
                      {JSON.parse(reportData.campaign_recipients).length}{" "}
                    </strong>
                    recipients.
                    {/* This represents{" "}
                    <strong>55%</strong> of the total targeted recipients */}
                  </p>
                </div>
                <div className="data_item">
                  <div className="bullet delivered"></div>
                  <p>
                    <strong>{reportData.delivered}</strong> recipients have
                    gotten this campaign email in their mail box.
                  </p>
                </div>
                <div className="data_item">
                  <div className="bullet clicks"></div>
                  <p>
                    <strong>{reportData.clicked}</strong> clicks on the action
                    button in this campaign.
                    {/* To maximize your <strong>ROI</strong>, we
                    recommend analyzing the post-click behavior and optimizing
                    your landing page for conversions. */}
                  </p>
                </div>
                <div className="data_item">
                  <div className="bullet opened"></div>
                  <p>
                    The campaign email was opened{" "}
                    <strong>{reportData.opened}</strong> times by{" "}
                    <strong>
                      {JSON.parse(reportData.campaign_recipients).length}{" "}
                    </strong>
                    recipients, averaging{" "}
                    <strong>
                      {Number(reportData?.opened) /
                        Number(
                          JSON.parse(reportData?.campaign_recipients)?.length
                        )}
                    </strong>{" "}
                    opens per recipient
                    {/* To
                    increase engagement, consider sending a follow-up email to
                    those who opened but didn't click any links. I can create a
                    segment for this. */}
                  </p>
                </div>
                <div className="data_item">
                  <div className="bullet unsubscribed"></div>
                  <p>
                    <strong>{reportData.unsubscribed}</strong> recipients
                    unsubscribed from getting your campaigns.
                  </p>
                </div>
                <div className="data_item">
                  <div className="bullet pending"></div>
                  <p>
                    You have <strong>{reportData.pending}</strong> recipients
                    pending.
                    {/* This means that we have less volume campaign to be
                    sent, which is great! */}
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <p className="check_back">
            Your campaign was sent successfully, kindly check back in{" "}
            <span>
              {Math.round(24 - hoursDiff)}
              hours
            </span>
            .
          </p>
        )}
      </div>
    </div>
  );
};

export default CampaignReport;
