import { createSlice } from "@reduxjs/toolkit";
import { API_URLS, getRequest, postRequest } from "../../api";

import { setAlert } from "../components/components-slice";

import { getRequestError, sendErrorLogsToDB } from "../../utils/functions";

type Props = {
  isFetchingAllNotifications: boolean;
  allNotifications: null;
  isFetchingNotifications: boolean;
  notifications: null;
  // isFetchingNotificationData: boolean;
  // notificationData: {};
  isUpdatingNotificationStatus: boolean;
  isDeletingNotification: boolean;
};
export const initialState: Props = {
  isFetchingAllNotifications: false,
  allNotifications: null,
  isFetchingNotifications: false,
  notifications: null,
  // isFetchingNotificationData: true,
  // notificationData: {},
  isUpdatingNotificationStatus: false,
  isDeletingNotification: false,
};

// Slice
const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setIsFetchingAllNotifications: (state, { payload }) => {
      state.isFetchingAllNotifications = payload;
    },
    getAllNotificationsSuccess: (state, { payload }) => {
      state.allNotifications = payload;
    },
    setIsFetchingNotifications: (state, { payload }) => {
      state.isFetchingNotifications = payload;
    },
    getNotificationsSuccess: (state, { payload }) => {
      state.notifications = payload;
    },
    // setIsFetchingNotificationData: (state, { payload }) => {
    //   state.isFetchingNotificationData = payload;
    // },

    setIsUpdatingNotificationStatus: (state, { payload }) => {
      state.isUpdatingNotificationStatus = payload;
    },
    setIsDeletingNotification: (state, { payload }) => {
      state.isDeletingNotification = payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  setIsFetchingAllNotifications,
  getAllNotificationsSuccess,
  setIsFetchingNotifications,
  getNotificationsSuccess,
  setIsUpdatingNotificationStatus,
  setIsDeletingNotification,
} = slice.actions;

export const getAllNotificationsAction =
  (params: { type: string; status: string }) => async (dispatch: any) => {
    try {
      dispatch(setIsFetchingAllNotifications(true));

      const res = await getRequest({
        url: API_URLS?.getNotifications,
        params,
      });

      dispatch(getAllNotificationsSuccess(res?.data?.data));
      dispatch(setIsFetchingAllNotifications(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      // dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsFetchingAllNotifications(false));
      await sendErrorLogsToDB(
        errorMessage,
        "notifications",
        " User tried to get All notifications"
      );
    }
  };
export const getNotificationsAction =
  (params: { type: string; status: string }) => async (dispatch: any) => {
    try {
      dispatch(setIsFetchingNotifications(true));

      const res = await getRequest({
        url: API_URLS?.getNotifications,
        params,
      });

      dispatch(getNotificationsSuccess(res?.data?.data));
      dispatch(setIsFetchingNotifications(false));

      return { success: true, notifications: res?.data?.data };
    } catch (err) {
      const errorMessage = getRequestError(err);
      // dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsFetchingNotifications(false));
      await sendErrorLogsToDB(
        errorMessage,
        "notifications",
        `User tried to get notifications of type: ${params.type} and status of type ${params.status}  `
      );
    }
  };

// export const getNotificationsByIdAction = (id: string) => async (dispatch: any) => {
//   try {
//     dispatch(setIsFetchingNotificationData(true));

//     const res = await getRequest({
//       url: `${API_URLS?.getCustomerById}/${id}`,
//       params: null,
//     });

//     dispatch(
//       getCustomerByIdSuccess({
//         ...res?.data,
//         customer_data: JSON.parse(res?.data?.customer_data),
//         customer_orders: JSON.parse(res?.data?.customer_orders),
//       })
//     );
//     dispatch(setIsFetchingNotificationData(false));

//     return { success: true };
//   } catch (err) {
//     const errorMessage = getRequestError(err);
//     dispatch(setAlert(true, "error", errorMessage));

//     dispatch(setIsFetchingNotificationData(false));
//     await sendErrorLogsToDB(
//       errorMessage,
//       "notifications",
//       "User tried to get notification by ID"
//     );
//   }
// };

export const updateNotificationStatusAction =
  (data: any, type?: "single" | "multiple") => async (dispatch: any) => {
    try {
      dispatch(setIsUpdatingNotificationStatus(true));

      const res = await postRequest({
        url:
          type === "multiple"
            ? API_URLS.updateMultipleNotificationsStatus
            : API_URLS?.updateNotificationStatus,
        params: null,
        data,
        formData: null,
      });
      if (type === "multiple")
        dispatch(setAlert(true, "success", res?.data?.message));
      dispatch(setIsUpdatingNotificationStatus(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      // dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsUpdatingNotificationStatus(false));
      await sendErrorLogsToDB(
        errorMessage,
        "notifications",
        "user tried to update notification status"
      );
    }
  };

export const deleteNotificationAction =
  (data: { id: number[] }) => async (dispatch: any) => {
    try {
      dispatch(setIsDeletingNotification(true));

      const res = await postRequest({
        url: API_URLS?.deleteNotification,
        params: null,
        data,
        formData: null,
      });

      dispatch(setAlert(true, "success", res?.data?.message));
      dispatch(setIsDeletingNotification(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      // dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsDeletingNotification(false));
      await sendErrorLogsToDB(
        errorMessage,
        "notifications",
        `user tried to delete notifications with ID(s): ${JSON.stringify(data)}`
      );
    }
  };
