import { useState, useEffect, useRef } from "react";

import poster from "../assets/img/poster.png";

interface VideoProps {
  source: string;
}

const Video = ({ source }: VideoProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [shouldLoadVideo, setShouldLoadVideo] = useState<boolean>(false);

  useEffect(() => {
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        (entries, observerInstance) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setShouldLoadVideo(true);
              observerInstance.disconnect();
            }
          });
        },
        { threshold: 0.25 } // Trigger when 25% of the container is visible
      );

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => observer.disconnect();
    } else {
      // Fallback if IntersectionObserver is not supported
      setShouldLoadVideo(true);
    }
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto",
        height: "100%",
      }}
    >
      {shouldLoadVideo ? (
        <video
          controls
          preload="metadata"
          poster={poster} // A low-size image as a preview
          style={{ width: "100%", height: "auto" }}
        >
          <source src={source} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        // Placeholder while the video hasn't loaded
        <div
          style={{ width: "100%", height: "360px", backgroundColor: "#000" }}
        />
      )}
    </div>
  );
};

export default Video;
