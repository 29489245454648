import Video from "../../components/video";

import doubleArrowIcon from "../../assets/svg/doubleAltArrowDown.svg";

interface VideoContentProps {
  scroll?: () => void;
}

const VideoContent = ({ scroll }: VideoContentProps) => {
  return (
    <>
      <p className="top_header">
        Increase open rate & boost sales with 1-to-1 personalized email
        marketing.
      </p>
      <Video source="https://api.konvas.bid/assets/videos/Konvas.mp4" />
      <p className="bottom_text">
        Say no more to rigid automation. Konvas AI does a one-to-one
        personalization for every campaign, helping your e-commerce store grow
        faster and smarter. 
      </p>
      <div className="scroll" onClick={scroll}>
        <img src={doubleArrowIcon} alt="" />
        <p>See more below</p>
      </div>
    </>
  );
};

export default VideoContent;
