import { createSlice } from "@reduxjs/toolkit";
import { API_URLS, getRequest, postRequest } from "../../api";

import { setAlert } from "../components/components-slice";

import {
  getRequestError,
  handleFetchAPIError,
  sendErrorLogsToDB,
} from "../../utils/functions";

type Props = {
  isFetchingInsights: boolean;
  insights: [];
  isFetchingInsightData: boolean;
  insightData: {};
  isCreatingInsight: boolean;
  isSavingInsight: boolean;
  isDeletingInsight: boolean;
};
export const initialState: Props = {
  isFetchingInsights: false,
  insights: [],
  isFetchingInsightData: false,
  insightData: {},
  isCreatingInsight: false,
  isSavingInsight: false,
  isDeletingInsight: false,
};

// Slice
const slice = createSlice({
  name: "insights",
  initialState,
  reducers: {
    setIsFetchingInsights: (state, { payload }) => {
      state.isFetchingInsights = payload;
    },
    getInsightsSuccess: (state, { payload }) => {
      state.insights = payload;
    },
    setIsFetchingInsightData: (state, { payload }) => {
      state.isFetchingInsightData = payload;
    },
    getInsightByIdSuccess: (state, { payload }) => {
      state.insightData = payload;
    },
    setIsCreatingInsight: (state, { payload }) => {
      state.isCreatingInsight = payload;
    },
    setIsSavingInsight: (state, { payload }) => {
      state.isSavingInsight = payload;
    },
    setIsDeletingInsight: (state, { payload }) => {
      state.isDeletingInsight = payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  setIsFetchingInsights,
  getInsightsSuccess,
  setIsFetchingInsightData,
  getInsightByIdSuccess,
  setIsCreatingInsight,
  setIsSavingInsight,
  setIsDeletingInsight,
} = slice.actions;

export const getInsightsAction = (params: any) => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingInsights(true));

    const res = await getRequest({
      url: API_URLS?.getInsights,
      params,
    });

    dispatch(getInsightsSuccess(res?.data?.data));
    dispatch(setIsFetchingInsights(false));

    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingInsights(false));
    await sendErrorLogsToDB(
      errorMessage,
      "insight",
      "user tried to get all insights"
    );
  }
};

export const getInsightsByIdAction = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingInsightData(true));

    const res = await getRequest({
      url: `${API_URLS?.getInsightById}/${id}`,
      params: null,
    });

    dispatch(getInsightByIdSuccess(res?.data));
    dispatch(setIsFetchingInsightData(false));

    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingInsightData(false));
    await sendErrorLogsToDB(
      errorMessage,
      "insight",
      "user tried to get an insight by ID"
    );
  }
};

export const generateAIInsightAction = () => async (dispatch: any) => {
  try {
    dispatch(setIsCreatingInsight(true));
    const response = await fetch(API_URLS?.generateAIInsight);
    await handleFetchAPIError(response);
    const resp = await response.json();

    dispatch(setIsCreatingInsight(false));
    return { success: resp?.success, data: resp?.data };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsCreatingInsight(false));
    await sendErrorLogsToDB(
      errorMessage,
      "insight",
      "user tried to get AI generated insights"
    );
  }
};

export const generateCustomInsightAction =
  (data: { user_query: string }) => async (dispatch: any) => {
    try {
      const response = await fetch(API_URLS?.generateCustomInsight, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      await handleFetchAPIError(response);
      const resp = await response.json();

      return { success: resp?.success, data: resp?.data };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
      await sendErrorLogsToDB(
        errorMessage,
        "insight",
        "user tried to create custom generated insights"
      );
    }
  };

export const postAddInsightToDbAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsSavingInsight(true));

      const res = await postRequest({
        url: API_URLS?.addInsight,
        params: null,
        data,
        formData: true,
      });
      dispatch(setAlert(true, "success", res?.data?.message));
      dispatch(setIsSavingInsight(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsSavingInsight(false));
      await sendErrorLogsToDB(
        errorMessage,
        "insight",
        "user tried to save generated insight to DB"
      );
    }
  };

export const getUpdateInsightStatusByIdAction =
  (status: string, id: string) => async (dispatch: any) => {
    try {
      dispatch(setIsCreatingInsight(true));

      const res = await getRequest({
        url: `${API_URLS?.updateInsightStatus}/${status}/${id}`,
        params: null,
      });

      dispatch(setIsCreatingInsight(false));

      return { success: res?.data?.status };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsCreatingInsight(false));
      await sendErrorLogsToDB(
        errorMessage,
        "insight",
        "user tried to update status of an insight"
      );
    }
  };

export const deleteInsightsAction = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setIsDeletingInsight(true));

    const res = await getRequest({
      url: `${API_URLS?.deleteInsight}/${id}`,
      params: null,
    });

    dispatch(setIsDeletingInsight(false));
    return { success: res?.data?.status };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsDeletingInsight(false));
    await sendErrorLogsToDB(
      errorMessage,
      "insight",
      "user tried to delete an insight"
    );
  }
};
