import featured_Icon_one from "../../assets/svg/Featured_one.svg";
import featured_Icon_two from "../../assets/svg/Featured_two.svg";
import featured_Icon_three from "../../assets/svg/Featured_three.svg";
import featured_Icon_four from "../../assets/svg/Featured_four.svg";
import featured_Icon_five from "../../assets/svg/Featured_five.svg";
import featured_Icon_six from "../../assets/svg/Featured_six.svg";
import stars from "../../assets/svg/Stars.svg";
import shopifyRibbon from "../../assets/svg/shopify-ribbon.svg";
import securityRibbon from "../../assets/svg/security-ribbon.svg";
import sheetsngigglesLogo from "../../assets/svg/sheets&giggles.svg";

import Button from "../../components/button";
import Logo from "../../components/logo";

interface Props {
  scrollToTarget?: () => void;
  contentRef?: any;
}

const PageContent = ({ scrollToTarget, contentRef }: Props) => {
  return (
    <div className="page_content">
      <div className="features">
        <div className="items" ref={contentRef}>
          <div className="content">
            <img src={featured_Icon_one} alt="" />
            <p className="heading">Integrated with your Shopify</p>
            <p>Seamless setup, syncs instantly</p>
          </div>
          <div>
            <div className="content">
              <img src={featured_Icon_two} alt="" />
              <p className="heading">Fully Automated, But Editable </p>
              <p>AI generates, you review & tweak anytime.</p>
              <div className="content mt">
                <img src={featured_Icon_four} alt="" />
                <p className="heading">
                  Personalizes every campaign to each customer 
                </p>
                <p>
                  Knows each customer’s behaviors, location-specific events,
                  weather, etc. to personalize every message.  
                </p>
              </div>
            </div>
          </div>
          <div className="content">
            <img src={featured_Icon_three} alt="" />
            <p className="heading">Built for Sales Growth </p>
            <p>Converts more customers with tailored messaging. </p>
          </div>
        </div>

        <div className="items">
          <div className="content">
            <img src={featured_Icon_five} alt="" />
            <p className="heading">Understands your Brand Preferences </p>
            <p>
              Learns from your store data, brand tones, writing approach, etc.
              to keep messaging on point 
            </p>
          </div>
          <div className="content">
            <img src={featured_Icon_six} alt="" />
            <p className="heading">Engage Smarter, Not Harder  </p>
            <p>
              AI segments customers for the best targeting and automates
              campaign creation. 
            </p>
          </div>
        </div>
        <div className="cta">
          <p className="heading">
            Join 100+ Shopify Sellers Growing Faster <br /> with AI 👍
          </p>
          <Button text={"Start free trial"} onClick={scrollToTarget} />
          <p>No credit card needed</p>
        </div>
      </div>

      <div className="testimonial">
        <p className="heading">Don’t just take our word for it</p>
        <p>Hear from one of our amazing customers.</p>
        <div className="rating">
          <img src={stars} alt="" />
        </div>
        <p className="comment">
          “Konvas(AI) did exactly what I've been waiting for an AI app to do –
          manage 1:1 conversations with thousands of potential customers that my
          team didn't have time to follow up with. Huge win for our acquisition
          and retention efforts to layer this on top of our CS team's hours.” ~
          <strong>Sheets & Giggles, United States.</strong>
        </p>
        <div className="logo">
          <img src={sheetsngigglesLogo} alt="" />
        </div>
      </div>

      <div className="footer">
        <div className="top">
          <div className="item">
            <img src={shopifyRibbon} alt="" />
            <p>
              Shopify <br /> Integration
            </p>
          </div>
          <div className="item">
            <img src={securityRibbon} alt="" />
            <p>
              Security <br /> Guaranteed
            </p>
          </div>
        </div>
        <div className="bottom">
          <Logo />
          <p>© {new Date().getFullYear()} Konvas AI. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default PageContent;
