import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import PageTitle from "../../components/page-title";

import FormInput from "../../components/form-input";
import Button from "../../components/button";

import { forgotPasswordAction } from "../../redux/auth/user-slice";

import { passwordFormValidation } from "../../utils/functions";
import Icon from "../../assets/svg";
// import Video from "../../components/video";
import PageContent from "./page-content";
import VideoContent from "./video-content";

type FormInputs = {
  password: string;
  confirmPassword: string;
};

const ResetPasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { user } = useSelector((state: any) => state.userSlice);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm: SubmitHandler<FormInputs> = async (data: any) => {
    setIsSubmitting(true);

    await dispatch(forgotPasswordAction(data)).then(() => {
      history.push("/");
    });

    setIsSubmitting(false);
  };

  if (user?.id && !isSubmitting) return <>{window.location.assign("/home")}</>;

  return (
    <React.Fragment>
      <PageTitle title="Reset Password" />

      <div className="sign_in_page">
        <div className="auth_container">
          <div className="left_side">
            <VideoContent />
          </div>

          <div className="form_container">
            <form className="form">
              <p className="title">Reset your password</p>
              <p className="sub_title">
                Enter a new password and confirm the new password.
              </p>

              <FormInput
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                readOnly={isSubmitting}
                errorMessage={errors?.password?.message}
                inputRef={{
                  ...register("password", passwordFormValidation(true)),
                }}
                leftIcon={<Icon name="lock" />}
                rightIcon={<Icon name={showPassword ? "eyeOff" : "eye"} />}
                iconClick={() => setShowPassword(!showPassword)}
              />

              <FormInput
                type={showPassword ? "text" : "password"}
                placeholder="Confirm Password"
                readOnly={isSubmitting}
                errorMessage={errors?.confirmPassword?.message}
                inputRef={{
                  ...register("confirmPassword", passwordFormValidation(true)),
                }}
                leftIcon={<Icon name="lock" />}
                rightIcon={<Icon name={showPassword ? "eyeOff" : "eye"} />}
                iconClick={() => setShowPassword(!showPassword)}
              />

              <div className="actions">
                <Button
                  text="Continue"
                  type="submit"
                  onClick={handleSubmit((data) => submitForm(data))}
                  loading={isSubmitting}
                  disabled={!isValid || isSubmitting}
                />

                <p className="sign_up_text">
                  <Link to="/">Back to Sign In</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
        <PageContent />
      </div>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
