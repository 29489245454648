import React, { useState } from "react";
import classNames from "classnames";

import { NotificationData } from ".";
import { letter } from "../../assets/img";
import { formatNotificationDate } from "../../utils/functions";
import Loader from "../../components/loader";
import NoData from "../../components/no-data";

import deleteIcon from "../../assets/svg/delete.svg";

// import Button from "../../components/button";

type Props = {
  setNotificationDetails: React.Dispatch<
    React.SetStateAction<NotificationData>
  >;
  setIsNotificationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleteNotificationModalOPen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  notifications: NotificationData[] | undefined;
  isFetchingNotifications: boolean;
  isUpdatingNotificationStatus: boolean;
  isDeletingNotification: boolean;
  notificationType: string;

  activeNotificationsId: string[];
  setActiveNotificationsId: React.Dispatch<React.SetStateAction<string[]>>;
  showDeleteAllSelectedNotificationIcon: boolean;
};

const NotificationListView = ({
  setNotificationDetails,
  setIsNotificationModalOpen,
  setIsDeleteNotificationModalOPen,
  notifications,
  isFetchingNotifications,
  isUpdatingNotificationStatus,
  isDeletingNotification,
  notificationType,
  activeNotificationsId,
  setActiveNotificationsId,
  showDeleteAllSelectedNotificationIcon,
}: Props) => {
  const handleClick = async (notification: NotificationData) => {
    setNotificationDetails(notification);
    setIsNotificationModalOpen(true);
  };

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    notificationId: string
  ) => {
    setActiveNotificationsId((prev) => {
      return prev?.includes(notificationId)
        ? prev.filter((id) => id !== notificationId)
        : [...prev, notificationId];
    });
  };

  return (
    <div className="notification_list">
      {isFetchingNotifications ||
      isUpdatingNotificationStatus ||
      isDeletingNotification ? (
        <Loader />
      ) : notifications?.length === 0 || !notifications ? (
        <NoData text={`no notifications found for ${notificationType}`} />
      ) : (
        <div className="inner">
          {notifications?.map((notification) => (
            <div
              className={classNames("item_container", {
                active: activeNotificationsId.includes(notification.id),
              })}
              // onClick={() => handleClick(notification)}

              key={notification.id}
            >
              <div className="item">
                <div className="checkbox">
                  <input
                    className="check-input"
                    type={"checkbox"}
                    onChange={(e) => handleCheck(e, notification.id)}
                    checked={activeNotificationsId.includes(notification.id)}
                  />
                </div>
                <div className="icon">
                  <img src={letter} alt="" />
                </div>
                <div className="item_content">
                  <p
                    className={classNames("item_title", {
                      read: notification.status === "read",
                    })}
                    onClick={() => handleClick(notification)}
                  >
                    {notification.activity}
                  </p>

                  <div className="item_detail">
                    <p
                      onClick={() => handleClick(notification)}
                      dangerouslySetInnerHTML={{
                        __html: `${notification.message}  <span>See more</span>`,
                      }}
                    >
                      {/* {notification.message} <span>See more</span> */}
                    </p>
                    <div className="right">
                      {activeNotificationsId.includes(notification.id) &&
                        !showDeleteAllSelectedNotificationIcon && (
                          <img
                            src={deleteIcon}
                            alt="delete-icon"
                            onClick={() => {
                              setNotificationDetails(notification);
                              setIsDeleteNotificationModalOPen(true);
                            }}
                          />
                        )}
                    </div>
                  </div>

                  <div
                    className="item_detail"
                    onClick={() => handleClick(notification)}
                  >
                    <p> {formatNotificationDate(notification.created_at)}</p>
                  </div>
                </div>
              </div>
              {/* <div className="action">
              <Button text="See analytics" />
            </div> */}
            </div>
          ))}

          {/* <div className="item_container" onClick={handleClick}>
          <div className="item">
            <div className="checkbox">
              <input className="check-input" type={"checkbox"} />
            </div>
            <div className="icon">
              <img src={letter} alt="" />
            </div>
            <div className="item_content">
              <p className="item_title">You have created your first campaign</p>
              <p className="item_detail">
                Weldone! Your "Fall Flash Sale" campaign is now live! Monitor
                performance and Monitor its performance in real-time using the
                campaign dashboard. Track key metrics like open rates,
                click-through rates, and conversions to measure its
                effectiveness and make adjustments as needed to optimize
                results.
              </p>
              <p className="item_detail">21st May 2024, 12:32pm</p>
            </div>
          </div>
        </div> */}
        </div>
      )}
    </div>
  );
};

export default NotificationListView;
