import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { Modal } from "reactstrap";

import SectionHeader from "../section-header";
import FormInput from "../form-input";
import Button from "../button";
import EnterPromptForm from "../enter-prompt";

import {
  generateAISegmentAction,
  generateCustomSegmentAction,
} from "../../redux/dashboard/segments-slice";

import Icon from "../../assets/svg";

type Props = {
  isOpen: boolean;
  close: any;
  onSubmit: (payload: any) => void;
};

const NewCustomSegmentModal = ({ isOpen, close, onSubmit }: Props) => {
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);
  const { isSavingSegment } = useSelector((state: any) => state.segmentsSlice);

  const [step, setStep] = useState<Number>(1);
  const [type, setType] = useState("");
  const [promptText, setPromptText] = useState<string>("");
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isErrorGeneratingSegment, setIsErrorGeneratingSegment] =
    useState<boolean>(false);
  const [generatedData, setGeneratedData] = useState<any>({});
  const [title, setTitle] = useState("");

  useEffect(() => {
    setStep(1);
    setTitle("");
  }, [isOpen]);

  const generateWithAI = async () => {
    if (isErrorGeneratingSegment) setIsErrorGeneratingSegment(false);
    setIsGenerating(true);
    setType("ai");

    try {
      const res = await dispatch(generateAISegmentAction());
      if (res?.success) {
        setGeneratedData(Array.isArray(res?.data) ? res?.data[0] : res?.data);
        setStep(2);
      }
      setIsGenerating(false);
    } catch (error) {
      setStep(2);
      setIsGenerating(false);
      setIsErrorGeneratingSegment(true);
    }
  };

  const handleGenerateCustom = async () => {
    if (isErrorGeneratingSegment) setIsErrorGeneratingSegment(false);
    setIsGenerating(true);
    setType("custom");

    try {
      const res = await dispatch(
        generateCustomSegmentAction({
          user_query: promptText,
        })
      );
      if (res?.success) {
        setGeneratedData(Array.isArray(res?.data) ? res?.data[0] : res?.data);
        // setPromptText("");
        setStep(2);
      }

      setIsGenerating(false);
    } catch (error) {
      setStep(2);
      setIsGenerating(false);
      setIsErrorGeneratingSegment(true);
    }
  };

  useEffect(() => {
    setTitle(generatedData?.segment_name);
    console.log("generatedData:", generatedData);
  }, [generatedData]);

  const handleSubmit = () => {
    const payload = {
      business_slug: user.company_slug,
      type,
      segment_name: title,
      segment_description: generatedData?.segment_description,
      customers: generatedData?.customers,
    };

    onSubmit(payload);
  };

  const isDisabled = () => {
    var value = false;
    if (!title) value = true;
    if (!generatedData?.segment_description) value = true;

    return value;
  };

  return (
    <Modal className="new_ai_segment_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={
            <div className="title_box">Segment {">"} New Generated Segment</div>
          }
          rightSide={
            <>
              <div
                className="icon"
                onClick={() => {
                  close();
                  setPromptText("");
                }}
              >
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      {step === 1 && (
        <div className="modal_body">
          <EnterPromptForm
            generateWithAI={generateWithAI}
            promptText={promptText}
            setPromptText={setPromptText}
            isCreating={isGenerating}
            generateDetails={handleGenerateCustom}
            placeholder="Enter a prompt to generate your segment"
          />
          <br />
          <br />
        </div>
      )}

      {step === 2 && (
        <div className="modal_body">
          {!generatedData?.customers ||
          isErrorGeneratingSegment ||
          generatedData?.customers?.length === 0 ? (
            <div className="info">
              <p>
                {isErrorGeneratingSegment
                  ? "Sorry, unable to generate segment, click on the 'retry' button to try again"
                  : type === "custom"
                  ? generatedData?.no_segment_reason
                  : "Sorry, unable to generate segment, click on the 'retry' button to try again"}
              </p>
              <Button
                text="Retry"
                disabled={isGenerating}
                onClick={
                  type === "custom" ? handleGenerateCustom : generateWithAI
                }
                loading={isGenerating}
              />
            </div>
          ) : (
            <>
              <div className="info">
                <label>Segment title:</label>
                <FormInput
                  type="text"
                  placeholder="Segment title"
                  value={title}
                  readOnly={isSavingSegment}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="info">
                <label>Segment details:</label>
                <p>{generatedData?.segment_description}</p>
              </div>

              <div className="info">
                <label>Segment customers:</label>
                <p>
                  You have{" "}
                  {Number(generatedData?.customers?.length).toLocaleString(
                    "en-US"
                  )}{" "}
                  customers in this segment.
                </p>
              </div>
              <Button
                text="Save New Segment"
                disabled={isDisabled()}
                onClick={handleSubmit}
                loading={isSavingSegment}
              />
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default NewCustomSegmentModal;
