import { useState } from "react";

import { useAppDispatch } from "../../redux";

import { NotificationData } from ".";
import Topbar from "../../components/topbar";
import NotificationDateFilter from "./notification-date-filter";
import {
  deleteNotificationAction,
  updateNotificationStatusAction,
} from "../../redux/dashboard/notification-slice";

import deleteIcon from "../../assets/svg/delete.svg";

type Props = {
  generateWithAI?: () => void;
  generateCustom?: () => void;
  onFilter?: (value: string) => any;
  viewTypeOptions: Array<{ label: string; value: string }>;
  viewType: { label: string; value: string };
  setViewType: React.Dispatch<
    React.SetStateAction<{ label: string; value: string }>
  >;
  getAllNotifications: (notifications: any) => void;
  notifications: NotificationData[];
  notificationsByType: NotificationData[] | undefined;
  setNotificationsByType: React.Dispatch<
    React.SetStateAction<NotificationData[] | undefined>
  >;
  allNotifications: NotificationData[] | undefined;
  activeNotificationsId: string[];
  setActiveNotificationsId: React.Dispatch<React.SetStateAction<string[]>>;
  getNotificationsByType: (params: { type: string; status: string }) => void;
  notificationTypeParams: { type: string; status: string };
  showDeleteIcon: boolean;
  setShowDeleteIcon: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleteNotificationModalOPen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

const NotificationsTopbar = ({
  viewType,
  setViewType,
  viewTypeOptions,
  getAllNotifications,
  notifications,
  notificationsByType,
  setNotificationsByType,
  allNotifications,
  activeNotificationsId,
  setActiveNotificationsId,
  getNotificationsByType,
  notificationTypeParams,
  showDeleteIcon,
  setShowDeleteIcon,
  setIsDeleteNotificationModalOPen,
}: Props) => {
  const dispatch = useAppDispatch();

  const [checked, setChecked] = useState(false);
  const [activeOptionType, setActiveOptionType] = useState("all");

  const markAllSelectedNotifications = async (status: string) => {
    const updateData = activeNotificationsId?.map((id) => {
      return {
        id: Number(id),
        status,
      };
    });
    const res = await dispatch(
      updateNotificationStatusAction(updateData, "multiple")
    );
    if (res?.success) {
      getNotificationsByType(notificationTypeParams);
      setActiveOptionType("all");
      setChecked(false);
    }
  };

  const handleSelectAll = (type: string) => {
    let allNotificationIDs: string[] = [];
    if (notificationsByType) {
      if (type === "all") {
        allNotificationIDs = notificationsByType?.map(
          (notification) => notification?.id
        );
      } else {
        allNotificationIDs = notificationsByType
          .filter((notification) => notification?.status === type)
          ?.map((notification) => notification?.id);
      }
    }

    setActiveOptionType(type);

    setActiveNotificationsId((prev) => {
      if (
        prev.some((id) => allNotificationIDs?.includes(id)) ||
        allNotificationIDs?.length === 0
      ) {
        setChecked(false);
        setShowDeleteIcon(false);
        return [];
      }

      setChecked(true);
      setShowDeleteIcon(true);
      return [...allNotificationIDs];
    });
  };

  const handleDeleteSelectedNotifications = async () => {
    setActiveOptionType("all");
    setChecked(false);
    setIsDeleteNotificationModalOPen(true);
  };

  const handleNotificationSearch = (value: string) => {
    console.log("searchValue:", value, value.length);

    setNotificationsByType((prev: NotificationData[] | undefined) => {
      if (!Array.isArray(prev) || value.length < 2) return notifications;

      return prev?.filter(
        (notification: NotificationData) =>
          notification.message.includes(value) ||
          notification.activity.includes(value)
      );
    });
  };

  const topbarFilters = [
    {
      type: "select",
      value: viewType,
      options: viewTypeOptions,
      onChange: (value: any) => setViewType(value),
    },
    {
      type: "tag",
      label: "All",
      value: allNotifications?.length?.toString(),
    },
    {
      type: "tag",
      label: "Unread",
      value: allNotifications
        ?.filter((notification) => notification?.status === "unread")
        ?.length?.toString(),
    },
    {
      type: "tag",
      label: "Read",
      value: allNotifications
        ?.filter((notification) => notification?.status === "read")
        ?.length?.toString(),
    },
    {
      type: "searchFilter",
      label: "Search anything...",
      children: (
        <NotificationDateFilter
          notifications={notifications}
          setNotificationsByType={setNotificationsByType}
        />
      ),
    },
    {
      type: "dropSelect",
      variant: {
        name: "checkbox",
        checked,
        action: () => handleSelectAll(activeOptionType),
      },
      options: [
        { label: "All", value: "all", action: () => handleSelectAll("all") },
        { label: "Read", value: "read", action: () => handleSelectAll("read") },
        {
          label: "Unread",
          value: "unread",
          action: () => handleSelectAll("unread"),
        },
      ],
      label: "Select All",
    },
    {
      type: "dropSelect",
      options: [
        {
          label: "Read",
          value: "read",
          action: () => markAllSelectedNotifications("read"),
        },
        {
          label: "Unread",
          value: "unread",
          action: () => markAllSelectedNotifications("unread"),
        },
      ],
      label: "Mark as",
    },
  ];

  return (
    <Topbar
      title="Notifications"
      onSearch={(value: any) => handleNotificationSearch(value)}
      filters={topbarFilters}
      getAllNotifications={getAllNotifications}
      hideLayoutStyle
      actionBtn={
        <>
          {showDeleteIcon ? (
            <img
              src={deleteIcon}
              alt="delete-icon"
              onClick={handleDeleteSelectedNotifications}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};

export default NotificationsTopbar;
