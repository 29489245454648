import classNames from "classnames";

import Icon from "../../assets/svg";
import { NotificationData } from ".";

type Props = {
  setNotificationParams: React.Dispatch<
    React.SetStateAction<{ type: string; status: string }>
  >;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  allNotifications: NotificationData[] | undefined;
};

const notificationTabData = [
  {
    name: "Campaigns",
    icon: "mail",
    type: "campaign",
    newItems: { color: "sky" },
  },
  {
    name: "Segments",
    icon: "grid",
    type: "segment",
    newItems: { color: "primary" },
  },
  {
    name: "Insights",
    icon: "star",
    type: "insight",
    newItems: { color: "amber" },
  },

  {
    name: "Customers",
    icon: "user",
    type: "customer",
    newItems: { color: "pink" },
  },
  {
    name: "Analytics",
    icon: "graph",
    type: "analytics",
    newItems: { color: "primary" },
  },
  // {
  //   name: "Co-pilot",
  //   icon: "exclude",
  //   newItems: { amount: "8 new", color: "amber" },
  // },
];

const NotificationTab = ({
  allNotifications,
  activeTab,
  setActiveTab,
  setNotificationParams,
}: Props) => {
  const handleClick = (name: string, type: string) => {
    setActiveTab(name);
    setNotificationParams({ type, status: "" });
  };

  const unreadNotifications = allNotifications?.filter(
    (notification) => notification.status === "unread"
  );

  return (
    <div className="notification_tab_container">
      <div className="inner">
        {notificationTabData.map(({ name, icon, type, newItems }) => (
          <div
            className={classNames("tab_item", { active: name === activeTab })}
            key={name}
            onClick={() => handleClick(name, type)}
          >
            <Icon name={icon} />
            <p className="name">{name}</p>
            {unreadNotifications &&
              unreadNotifications?.filter(
                (notification) => notification.type === type
              ).length !== 0 && (
                <p
                  className={classNames("new", {
                    primary: newItems.color === "primary",
                    amber: newItems.color === "amber",
                    sky: newItems.color === "sky",
                    pink: newItems.color === "pink",
                  })}
                >
                  {
                    unreadNotifications?.filter(
                      (notification) => notification.type === type
                    ).length
                  }{" "}
                  new
                </p>
              )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationTab;
